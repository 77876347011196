<template>
  <img ref="img" src="" alt="" @click="onClick($event)">
</template>

<script>
export default {
  name: "AuthImg",
  props: {
    authSrc: {
      type: String,
      default: "",
    }
  },
  mounted() {
    this.loadImg();
  },
  watch: {
    authSrc: {
      handler() {
        this.loadImg();
      }
    }
  },
  methods: {
    loadImg() {
      let img = this.$refs.img
      img.src = require("../assets/logo.png");

      if(this.authSrc && this.authSrc.length > 0){
        let request = new XMLHttpRequest();
        request.responseType = "blob";
        request.open("GET", this.authSrc, true);
        request.setRequestHeader("X-Authorization", `Basic${this.$store.state.user.token}`);
        request.onreadystatechange = e => {
          if(request.readyState === XMLHttpRequest.DONE && request.status === 200){
            img.src = URL.createObjectURL(request.response);
            img.onload = () => {
              URL.revokeObjectURL(img.src);
            }
          }
        };
        request.send();
      }
    },
    onClick(event) {
      this.$emit('clicked', event)
    },
    saveImg() {
      let request = new XMLHttpRequest();
      request.responseType = "blob";
      request.open("GET", this.authSrc, true);
      request.setRequestHeader("X-Authorization", `Basic${this.$store.state.user.token}`);
      request.onreadystatechange = e => {
        if(request.readyState === XMLHttpRequest.DONE && request.status === 200){
          let msg = request.getResponseHeader('content-disposition');
          if (typeof msg === 'string' && msg.indexOf('fileName') > 0) {
            msg = msg.substring(msg.indexOf('fileName') + 9)
          } else {
            msg = `${(Math.random() * 1000000).toFixed(0)}.jpg`
          }

          const blob = new Blob([request.response], {type:request.response.type});
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = msg;
          link.click();
        }
      };
      request.send();
    }
  }
}
</script>
